import React, { useState } from 'react';
import styles from './App.module.scss';
import { data } from './data'

const App=() => {
  const [filtered, setFiltered]=useState([])
  const onChange = (evt) => {
    const valor = evt.target.value || "";
    setFiltered(main.filter(m => m.name.includes(valor)));
  }
  const main = filtered.length > 0 ? filtered : data;
  return (
    <div className={styles.container}>
      <input type="text" placeholder="Buscar" onChange={onChange}/>
      <div className={styles.phoneContainer}>
        {main.map(d => <div className={styles.phone}>
          <span className={`${styles.name}`}> {d.name}</span>
          <span> {d.number}</span>
        </div>)}
      </div>
    </div>
  );
};

export default App;
