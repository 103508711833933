export const data=[
  {
    name: "PCR",
    number: 5145
  },
  {
    name: "Ortensia bodega ssqq",
    number: 7421
  },
  {
    name: "Pre operatorio ",
    number: "1221- 1222- 1223"
  },
  {
    name: "Uti ecmo",
    number: 5862
  },
  {
    name: "Recuperación 5236",
    number: 5235
  },
  {
    name: "Pre nocturno ",
    number: "5232 - 5231"
  },
  {
    name: "Sala Anestesia",
    number: "5251 - 5250"
  },
  {
    name: "Sala Anestesia E.u",
    number: 5252
  },
  {
    name: "Aux sala",
    number: 5229
  },
  {
    name: "Anexo colaciones rocio",
    number: 4044
  },
  {
    name: "Pab mater",
    number: 1371
  },
  {
    name: "Admisión",
    number: 3125
  },
  {
    name: "Campana",
    number: 5039
  },
  {
    name: "Soporte Internet",
    number: 6911
  },
  {
    name: "RNM",
    number: 5935
  },
  {
    name: "ECO INTRA OP",
    number: 6911
  },
  {
    name: "uti",
    number: 5381
  },
  {
    name: "Urgencia",
    number: 5932
  },
  {
    name: "Hemato /  onco ",
    number: "4654 - 5486"
  },
  {
    name: "Secretaria Sala biopsia rapida",
    number: 5631
  },
  {
    name: "patologia rapida",
    number: 5884
  },
  {
    name: "banco sangre",
    number: 5195
  },
  {
    name: "medicina nuclear",
    number: 5020
  },
  {
    name: "imagenes de mama",
    number: 5985
  },
  {
    name: "EEmm",
    number: 3026
  },
  {
    name: "operador",
    number: 7426
  },
  {
    name: "Osvaldo",
    number: 7420
  },
  {
    name: "Instrumental",
    number: 7411
  },
  {
    name: "Descontaminacion",
    number: 5229
  },
  {
    name: "Consignación",
    number: 2426
  },
  {
    name: "Bodega central",
    number: "3062 - 3064"
  },
  {
    name: "Bodega",
    number: 7419
  },
  {
    name: "Bodega (gaveta azul)",
    number: 7411
  },
  {
    name: "Consignación (gavetas blanca)",
    number: 7417
  },
  {
    name: "Mantencion t O",
    number: 3020
  },
  {
    name: "Computador",
    number: 6911
  },
  {
    name: "Laboratorio",
    number: 5161
  },
  {
    name: "Quimica resultado ex:",
    number: 5186
  },
  {
    name: "Bala pabellon",
    number: 51
  },
  {
    name: "Tubos tioglicolato",
    number: 5185
  },
  {
    name: "Jefe Central Bodega",
    number: 5141
  },
  {
    name: "Bodega reposición",
    number: 7421
  },
  {
    name: "Personal",
    number: 3050
  },
  {
    name: "Farmacia 5703/",
    number: 5973
  },
  {
    name: "Trauma pediátrico/Yeso plástico",
    number: 8679
  },
  {
    name: "Imagen de la mama",
    number: "5985-51#2"
  },
  {
    name: "Preoperatorio",
    number: 5864
  },
  {
    name: "bodega (CIQ)",
    number: 5219
  },
  {
    name: "bodega central (gavetas)",
    number: 7419
  },
  {
    name: "Esterilización",
    number: 7411
  },
  {
    name: "Operador de reciclaje",
    number: 7426
  },
  {
    name: "UTI5385, ,",
    number: "5381 - 5380"
  },
  {
    name: "UTI pediatrica",
    number: "7751 - 7752"
  },
  {
    name: "Oficina Xime",
    number: 527
  },
  {
    name: "Agendamiento",
    number: "5881 - 3850"
  },
  {
    name: "Traumatologia consultas",
    number: "8674 - 8670"
  },
  {
    name: "Ecografía",
    number: 5173
  },
  {
    name: "PCR Covid",
    number: 5145
  },
  {
    name: "Quimica",
    number: 5186
  },
  {
    name: "Hematologia", number: 5188
  },
  {
    name: "Bacteriologia", number: 5185
  },
  {
    name: "Secretaria Vivi",
    number: "5221 - 5220"
  },
  {
    name: "Preparación carros",
    number: 7420
  },
  {
    name: "Intermedio neurológico",
    number: "5452 - 5455"
  },
  {
    name: "Laboratorio Recep. muestras",
    number: 51610
  },
  {
    name: "Banco sangre",
    number: "5196 - 5195"
  },
  {
    name: "Microbiología",
    number: 5162
  },
  {
    name: "Citometria de flujo",
    number: 4081
  },
  {
    name: "Gastro",
    number: "3842-3840-3824"
  },
  {
    name: "Anestesia",
    number: "5250-5251"
  },
  {
    name: "Anatomía patológica",
    number: 5884
  },
  {
    name: "sala bp rapida tecnica Mohs) -",
    number: 5631
  },
  {
    name: "Consultas Cx Plástica",
    number: 8915
  },
  {
    name: "Farmacia ",
    number: "5956 5958 5973"
  },
  {
    name: "Bodega Farmacia",
    number: 5032
  },
  {
    name: "Recetario Magistral Servimaster",
    number: 5718
  },
  {
    name: "Spinraza",
    number: 5958
  },
  {
    name: "Señora Ortencia (consulta de insumos)",
    number: 7421
  },
  {
    name: "consignación o tránsito",
    number: 7417
  },
  {
    name: "Instrumental",
    number: 7411
  },
  {
    name: "Baterías motor",
    number: 7420
  },
  {
    name: "Operador autoclave",
    number: 7426
  },
  {
    name: "consignación",
    number: 7417
  },
  {
    name: "preparación carro",
    number: 7420
  },
  {
    name: "enfermera central",
    number: 7424
  },
  {
    name: "Trasladó servimaster",
    number: 5718
  },
  {
    name: "Campana quimio",
    number: 5039
  },
  {
    name: "Pabellon Maternidad",
    number: 1360
  },
  {
    name: "equipos médicos - mantencion",
    number: 3020
  },
  {
    name: "Mesa se ayuda",
    number: 6911
  },
  {
    name: "Eu claudia enfermera bodega.",
    number: 5031
  },
  {
    name: "Eco intraoperatoria",
    number: "5173-3854"
  },
  {
    name: "resonancia post op",
    number: 5935
  },
  {
    name: "scaner",
    number: "5878 5182 5941"
  },
  {
    name: "Xime mujer",
    number: 5270
  },
  {
    name: "Potenciales evocados",
    number: 3712
  },
  {
    name: "Biopsia rápida",
    number: "5631.5632.5884"
  },
  {
    name: "Banco de sangre",
    number: 5195
  },
  {
    name: "Recepción de muestras",
    number: 5161
  },
  {
    name: "Receocion farmacia",
    number: 7428
  },
  {
    name: "Lab hematologia",
    number: 5188
  },
  {
    name: "Lab química ",
    number: "5186. 5187"
  },
  {
    name: "Lab Microbologia",
    number: 5162
  },
  {
    name: "Admisión  no",
    number: "3111"
  },
  {
    name: "Hemato onco",
    number: 4654
  },
  {
    name: "Uti pediatrica",
    number: 7751
  },
  {
    name: "Uti adulto",
    number: "5380. 5382"
  },
  {
    name: "Neo",
    number: 5367
  },
  {
    name: "Lit células",
    number: 3510
  },
  {
    name: "Solicitud cama",
    number: 5234
  },
  {
    name: "Consulta Traumatologia",
    number: 8674
  },
  {
    name: "Consulta cirugía cardiovascular",
    number: 8521
  },
  {
    name: "Uniforme",
    number: 3005
  },
  {
    name: "Coordinadora bodega",
    number: 7420
  },
  {
    name: "Rrhh3083",
    number: 3087
  },
  {
    name: "francisa riquelme",
    number: 3054
  },
  {
    name: "Sindicato",
    number: 3002
  },
  {
    name: "Solicitud cama",
    number: "+56968363097"
  },
  {
    name: "Sra Raquel búsqueda de restos erva:",
    number: "+5698747120"
  },
  {
    name: "Anexo HO ",
    number: "4655 - 5486"
  },
  {
    name: "Otorrino",
    number: 8556
  },

]

